import {
  SUBSCRIPTION_PLAN_ADVANCED,
  SUBSCRIPTION_PLAN_ONBOARDING,
  SUBSCRIPTION_PLAN_PREMIUM
} from '@/constants/subscriptionPlans.js'
import { ROLE_COMPANY_RIDER } from '@/constants/userRoles.js'
import getRoleIsCompanyRole from '@/helpers/getRoleIsCompanyRole.js'
import { SET_PARTIAL_SUBSCRIPTION_DATA, SET_SUBSCRIPTIONS } from '@/store/mutationTypes.js'

const state = () => ({
  subscriptions: []
})

const getters = {
  districtsIsAvailable: (state, getters, rootState, rootGetters) => {
    return true
  },
  companyHasFreePlan: (state, getters, rootState, rootGetters) => {
    const someMainSubscriptionIsActivated = getters.companyHasOnboardingPlan || getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan
    return !state.subscriptions.length || !someMainSubscriptionIsActivated
  },
  companyHasOnboardingPlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_ONBOARDING)
  },
  companyHasAdvancedPlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_ADVANCED)
  },
  companyHasPremiumPlan: (state, getters, rootState, rootGetters) => {
    if (!state.subscriptions.length) return false
    return state.subscriptions.includes(SUBSCRIPTION_PLAN_PREMIUM)
  },
  chatbotIsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  companyBranchesEnabled: (state, getters, rootState, rootGetters) => {
    const userRole = rootGetters['auth/userRole']
    if (userRole === ROLE_COMPANY_RIDER) { return false }
    return getRoleIsCompanyRole(userRole)
  },
  massMessagesEnabled: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['auth/userRoleIsCompanyAdmin'] && !rootGetters['auth/userRoleIsCompanyManager']) { return false }

    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  whatsappSupportEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  unlimitedLinksEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  googleRatingsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  getReviewsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  excelExportEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  automaticPrintingEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  statsPageEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  seeDeliveryItineraryEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  editDeliveryCostEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  settingFacebookPixelEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  settingFacebookDomainValidationEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  settingGoogleTagManagerEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  settingGoogleAnalyticsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  markPreparedKitchenOrderEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  addBranchOfficeEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  unlimitedUsersEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  downloadPdfMenuEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  autoStockSwitchEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  showCrmTotalAmountEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  showCrmOrdersCountEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  showUpgradeYourPlan: (state, getters, rootState, rootGetters) => {
    // commented temporarily, requested by @morgan
    // if (rootGetters['flutter/panelIsEmbedInFlutter']) { return false }
    // if (rootGetters['flutter/panelIsInIosMobile']) { return false }
    if (rootGetters['auth/userRoleIsCompanyRider']) { return false }
    return rootGetters['auth/userHasCompanyRol']
  },
  restockModalEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  campaignsEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  selectPrintAppEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  showAllOrdersKpisEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  showAllProductImagesEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  editLandingStylesEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  deliveryCostTypesEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  verificationWithGoogleMapEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  },
  supportDirectEnabled: (state, getters, rootState, rootGetters) => {
    return getters.companyHasAdvancedPlan || getters.companyHasPremiumPlan || getters.companyHasOnboardingPlan
  }
}

const actions = {
  setSubscriptions ({ commit }, { subscriptions }) {
    commit(SET_SUBSCRIPTIONS, { subscriptions })
  },
  setPartialSubscriptionData ({ commit }, { subscriptionId, data }) {
    commit(SET_PARTIAL_SUBSCRIPTION_DATA, { subscriptionId, data })
  }
}

const mutations = {
  [SET_SUBSCRIPTIONS] (state, { subscriptions }) {
    state.subscriptions = subscriptions
  },
  [SET_PARTIAL_SUBSCRIPTION_DATA] (state, { subscriptionId, data }) {
    const subscription = state.subscriptions.find(item => item.id === subscriptionId)

    if (!subscription) { return }

    for (const [key, value] of Object.entries(data)) {
      subscription[key] = value
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
