import {
  CHATBOT_MESSAGE_CONTENT_TYPE_RECOVER_CLIENT,
  CHATBOT_MESSAGE_CONTENT_TYPE_REVIVE_CLIENT,
  CHATBOT_MESSAGE_CONTENT_TYPE_CONVERT_IN_CLIENT,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_PENDING,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_ACCEPTED,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_READY_FOR_DELIVERY,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DRIVER_ARRIVED_AT_DESTINATION,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DELIVERY_ON_THE_WAY,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DELIVERED,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_COMPLETED,
  CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_CANCELLED,
  CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_OPEN,
  CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_CLOSED_SCHEDULED_ORDER,
  CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_CLOSED_NO_SCHEDULED_ORDER,
  CHATBOT_MESSAGE_CONTENT_TYPE_BUSINESS_HOURS,
  CHATBOT_MESSAGE_CONTENT_TYPE_INFORMATION,
  CHATBOT_MESSAGE_CONTENT_TYPE_PLACE_AN_ORDER,
  CHATBOT_MESSAGE_CONTENT_TYPE_PROMOTION,
  CHATBOT_MESSAGE_CONTENT_TYPE_RATING,
  CHATBOT_MESSAGE_CONTENT_TYPE_LOYALTY
} from '@/constants/chatbotMessageContentType.js'

const getDataToUpdateChatbotMessage = (chatbotSettingsMessage) => {
  const turboSalesMessage = chatbotSettingsMessage?.turbo_sales ?? {}
  const ordersMessage = chatbotSettingsMessage?.orders?.status_update ?? {}
  const greetingMessage = chatbotSettingsMessage?.greetings ?? {}

  const data = {
    messages: {
      turbo_sales: {
        recover_client: {
          text: turboSalesMessage?.recover_client?.text ?? null,
          active: turboSalesMessage?.recover_client?.active ?? true,
          type: CHATBOT_MESSAGE_CONTENT_TYPE_RECOVER_CLIENT
        },
        revive_client: {
          text: turboSalesMessage?.revive_client?.text ?? null,
          active: turboSalesMessage?.revive_client?.active ?? true,
          type: CHATBOT_MESSAGE_CONTENT_TYPE_REVIVE_CLIENT
        },
        convert_in_client: {
          text: turboSalesMessage?.convert_in_client?.text ?? null,
          active: turboSalesMessage?.convert_in_client?.active ?? true,
          type: CHATBOT_MESSAGE_CONTENT_TYPE_CONVERT_IN_CLIENT
        }
      },
      orders: {
        status_update: {
          pending: {
            text: ordersMessage.pending?.text ?? null,
            active: ordersMessage?.pending?.active ?? true,
            type: ordersMessage?.preparing?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_PENDING
          },
          preparing: {
            text: ordersMessage.preparing?.text ?? null,
            active: ordersMessage?.preparing?.active ?? true,
            type: ordersMessage?.preparing?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_ACCEPTED
          },
          ready: {
            text: ordersMessage.ready?.text ?? null,
            active: ordersMessage?.ready?.active ?? true,
            type: ordersMessage?.ready?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_READY_FOR_DELIVERY
          },
          driver_arrived_at_destination: {
            text: ordersMessage.driver_arrived_at_destination?.text ?? null,
            active: ordersMessage?.driver_arrived_at_destination?.active ?? true,
            type: ordersMessage?.driver_arrived_at_destination?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DRIVER_ARRIVED_AT_DESTINATION
          },
          driver_on_the_way_to_destination: {
            text: ordersMessage.driver_on_the_way_to_destination?.text ?? null,
            active: ordersMessage?.driver_on_the_way_to_destination?.active ?? true,
            type: ordersMessage?.driver_on_the_way_to_destination?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DELIVERY_ON_THE_WAY
          },
          delivered: {
            text: ordersMessage.delivered?.text ?? null,
            active: ordersMessage?.delivered?.active ?? true,
            type: ordersMessage?.delivered?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DELIVERED
          },
          finalized: {
            text: ordersMessage.finalized?.text ?? null,
            active: ordersMessage?.finalized?.active ?? true,
            type: ordersMessage?.finalized?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_COMPLETED
          },
          cancelled: {
            text: ordersMessage.cancelled?.text ?? null,
            active: ordersMessage?.cancelled?.active ?? true,
            type: ordersMessage?.cancelled?.type || CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_CANCELLED
          }
        }
      },
      greetings: {
        open: {
          text: greetingMessage?.open?.text ?? null,
          footer: greetingMessage?.open?.footer ?? null,
          active: greetingMessage?.open?.active ?? true,
          type: greetingMessage?.open?.type || CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_OPEN
        },
        closed_scheduled_order: {
          text: greetingMessage?.closed_scheduled_order?.text ?? null,
          active: greetingMessage?.closed_scheduled_order?.active ?? true,
          type: greetingMessage?.closed_scheduled_order?.type || CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_CLOSED_SCHEDULED_ORDER
        },
        closed_no_scheduled_order: {
          text: greetingMessage?.closed_no_scheduled_order?.text ?? null,
          active: greetingMessage?.closed_no_scheduled_order?.active ?? true,
          type: greetingMessage?.closed_no_scheduled_order?.type || CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_CLOSED_NO_SCHEDULED_ORDER
        }
      },
      business_hours: {
        text: chatbotSettingsMessage?.business_hours?.text ?? null,
        active: chatbotSettingsMessage?.business_hours?.active ?? true,
        type: chatbotSettingsMessage?.business_hours?.type || CHATBOT_MESSAGE_CONTENT_TYPE_BUSINESS_HOURS
      },
      information: {
        text: chatbotSettingsMessage?.information?.text ?? null,
        active: chatbotSettingsMessage?.information?.active ?? true,
        type: chatbotSettingsMessage?.information?.type || CHATBOT_MESSAGE_CONTENT_TYPE_INFORMATION
      },
      promotion: {
        text: chatbotSettingsMessage?.promotion?.text ?? null,
        active: chatbotSettingsMessage?.promotion?.active ?? true,
        type: chatbotSettingsMessage?.promotion?.type || CHATBOT_MESSAGE_CONTENT_TYPE_PROMOTION
      },
      place_an_order: {
        text: chatbotSettingsMessage?.place_an_order?.text ?? null,
        active: chatbotSettingsMessage?.place_an_order?.active ?? true,
        type: chatbotSettingsMessage?.place_an_order?.type || CHATBOT_MESSAGE_CONTENT_TYPE_PLACE_AN_ORDER
      },
      rating: {
        text: chatbotSettingsMessage?.rating?.text ?? null,
        active: chatbotSettingsMessage?.rating?.active ?? true,
        type: chatbotSettingsMessage?.rating?.type || CHATBOT_MESSAGE_CONTENT_TYPE_RATING
      },
      loyalty: {
        text: chatbotSettingsMessage?.loyalty?.text ?? null,
        active: chatbotSettingsMessage?.loyalty?.active ?? false,
        type: chatbotSettingsMessage?.loyalty?.type || CHATBOT_MESSAGE_CONTENT_TYPE_LOYALTY
      }
    }
  }
  return data
}

export default getDataToUpdateChatbotMessage
